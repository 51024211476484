<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container">
    <div class="row">

      <div class="col-md-12 products-container" v-bind:class="{ 'open-filter-container-mobile': filterContainerActive }">
        <div class="d-flex">
          <div class="me-auto" v-if="roomName">
            <ProductCategoryTitle :title="$t(roomName)"/>
          </div>
        </div>
        <div>
          <ProductCategoriesGrid :categories="categories"/>
        </div>
      </div>
    </div>
  </div>
</template>

