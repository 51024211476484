<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'marketplace-adv': true}">
    <div class="marketplace-ad-container ">
     <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: categoryId}}" class="marketplace-ad-title">
      <div class="thumb-image" v-bind:style="{'background-image': 'url(/static/' + title + '.jpg' + ')'}">
      </div>
      <div class="marketplace-ad-text">{{ $t(title) }}</div>
     </router-link>
    </div>
  </div>
</template>

