<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'progress-slider-grey': theme === 'grey',
  }">
    <div class="grid-container">
      <div class="grid-products row">
        <div class="marketplace-grid-item col-md-2 col-6" v-for="category in categories">
          <div class="inner-grid-item">
            <ProductCategory :categoryId="category.id" :title="category.title" :image="category.image" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

