import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import ProductCategory from "@/views/MarketplaceProductCategories/ProductCategory/ProductCategory";


export default {
    name: 'ProductCategoriesGrid',
    props: {
        categories: {
            type: Array,
            default: () => []
        }
    },
    components: {
        CheckBox,
        MarketplaceAd,
        ProductCategory
    },
    mounted() {
    },
    data() {
        return {
            filterBoxActive: false,
        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        }
    }
}

