import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import LabelDiscount from "@/components/Common/Labels/LabelDiscount/LabelDiscount";


export default {
    name: 'ProductCategory',
    props: {
        categoryId: {
            type: Number,
            default: null
        },
        image: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
    },
    components: {
        LabelNew,
        LabelDiscount
    },
    mounted() {
    }
}